import Request from './../request';
import moment from 'moment';
import Cookies from 'js-cookie';
import { IUserResourceShortProps } from '../../props/users';
import { EXPIRY, REFRESH, TOKEN, USER } from '../../props/keys';

// import interfaces

const UserServices = {
  login: async (username: string, password: string, remember?: boolean): Promise<any> => {
    try {
      const result = await Request.post(
        '/api/login',
        {
          username,
          password,
        },
        undefined,
        true
      );
      const expires = moment()
        .add(result.token.expires_in - 60, 'seconds')
        .toISOString();
      Cookies.set(TOKEN, result.token.access_token, {
        expires: 7,
      });
      Cookies.set(REFRESH, result.token.refresh_token, {
        expires: 7,
      });
      Cookies.set(EXPIRY, expires, { expires: 7 });
      result.user && Cookies.set(USER, result.user, { expires: 7 });
      return result;
    } catch (e) {
      throw e;
    }
  },
  logout: async () => {
    try {
      await Request.post('/api/logout');
      Cookies.remove(TOKEN);
      Cookies.remove(REFRESH);
      Cookies.remove(EXPIRY);
      Cookies.remove(USER);
      return {};
    } catch (e) {
      throw e;
    }
  },
  currentUser: async (): Promise<IUserResourceShortProps> => {
    try {
      const results = await Request.get(`/api/me`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  retrieve: async (): Promise<IUserResourceShortProps[]> => {
    try {
      const results = await Request.get(`/api/users`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  updateEmail: async (data: FormData): Promise<{ user: IUserResourceShortProps; otpId?: string }> => {
    try {
      const results = await Request.post(`/api/updateEmail`, data);
      return results;
    } catch (e) {
      throw e;
    }
  },
  updatePassword: async (data: FormData): Promise<IUserResourceShortProps> => {
    try {
      const results = await Request.post(`/api/updatePassword`, data);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData): Promise<IUserResourceShortProps> => {
    try {
      const results = await Request.post(`/api/users`, data);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default UserServices;
