import React from 'react';

// import ui
import { Layout } from 'antd';

// import styles
import styles from './styles.module.scss';
import { blue, green } from '@ant-design/colors';
import LoginPage from './subPages/login';
import { Stack } from '@fluentui/react';

type AuthPageProps = {};

const AuthPage: React.FC<AuthPageProps> = (props) => {
  return (
    <Stack horizontal className={styles.container}>
      <Stack className={styles.sideLeft}>
        <LoginPage />
      </Stack>
      <Stack className={styles.sideRight} style={{ backgroundColor: blue.primary }}></Stack>
    </Stack>
  );
};

export default AuthPage;
