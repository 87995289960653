import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faPen, faPlus, faTrash } from '@fortawesome/pro-light-svg-icons';
import { initializeIcons, registerIcons, Stack } from '@fluentui/react';

// import pages
import HomePage from './pages/home';
import AuthPage from './pages/auth';

// import components

type AppProps = {};

initializeIcons();

registerIcons({
  icons: {
    Plus: <FontAwesomeIcon icon={faPlus} />,
    CircleUser: <FontAwesomeIcon icon={faCircleUser} />,
    Trash: <FontAwesomeIcon icon={faTrash} />,
    Pen: <FontAwesomeIcon icon={faPen} />,
  },
});

const App: React.FC<AppProps> = (props) => {
  return (
    <Stack>
      <Routes>
        <Route path='/*' element={<HomePage />} />
        <Route path='/auth/*' element={<AuthPage />} />
      </Routes>
    </Stack>
  );
};

export default App;
