import React from 'react';
import { Stack, Text } from '@fluentui/react';

// import context
import { useGlobalState } from '../../../../context/global';

type DashboardProps = {};

const Dashboard: React.FC<DashboardProps> = (props) => {
  const { state } = useGlobalState();
  return (
    <Stack>
      <Text>{state.user?.details?.name}</Text>
    </Stack>
  );
};

export default Dashboard;
