import { TAction, TState } from './types';

export function reducer(state: TState, action: TAction) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isSignedIn: true,
      };
    case 'LOGOUT':
      return {
        ...state,
        isSignedIn: false,
      };
    case 'USER':
      return {
        ...state,
        user: action.payload,
      };
    default:
      throw new Error('Unexpected action');
  }
}
