import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Upload, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import getBase64 from '../../../../../utils/getBase64';

interface IPicturesWallProps {
  onUpdate: (fileList: UploadFile<any>[]) => void;
  fileList?: UploadFile[];
  onRemoveFile?: (file: UploadFile<any>) => void;
  onAddFile?: (file: RcFile) => void;
}

const PicturesWall: React.FC<IPicturesWallProps> = (props) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | undefined>('');
  const [previewTitle, setPreviewTitle] = useState<string | undefined>('');
  const [fileList, setFileList] = useState<UploadFile[]>(props.fileList || []);

  useEffect(() => {
    props.onUpdate(fileList);
    return () => {};
  }, [fileList]);

  const handlePreview = async (file: UploadFile<unknown>) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file?.url || file?.preview);
    setPreviewVisible(true);
    setPreviewTitle(file?.name || file?.url?.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({ fileList }: UploadChangeParam<UploadFile<any>>) => {
    setFileList(fileList);
  };

  const beforeUpload = (file: RcFile, FileList: RcFile[]) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    if (isJpgOrPng && isLt2M) {
      onAddFile(file, FileList);
    }
    return isJpgOrPng && isLt2M;
  };

  const onAddFile = (file: RcFile, FileList: RcFile[]) => {
    if (props.onAddFile) {
      props.onAddFile(file);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleCancel = () => setPreviewVisible(false);

  return (
    <>
      <Upload
        listType='picture-card'
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        onRemove={(f) => {
          if (props.onRemoveFile) {
            props.onRemoveFile(f);
          }
        }}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default PicturesWall;
