import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  DatePicker,
  DefaultButton,
  Dropdown,
  IBasePickerSuggestionsProps,
  IDropdownOption,
  ITag,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
  TagPicker,
  Text,
  TextField,
} from '@fluentui/react';
import moment from 'moment';
import { UploadFile } from 'antd';

// import services
import { validationService } from '../../../../../services/validation';
import ProjectServices from '../../../../../services/projects';

// import config
import GeneralConfig from '../../../../../config';

// import utils
import countries from '../../../../../utils/countries.json';

// import components
import PicturesWall from '../components/uploadFile';

type AddProjectPanelProps = {
  onCLose(r?: boolean): void;
};

type DataType = {
  year?: string;
  projectType: string;
  name?: string;
  address?: string;
  city?: string;
  country?: string;
  description?: string;
  isPromoted: number;
  startDate?: string;
  duration?: string;
  errorName?: string;
  errorAddress?: string;
  errorDescription?: string;
  errorDuration?: string;
  errorYear?: string;
};

const AddProjectPanel: React.FC<AddProjectPanelProps> = (props) => {
  const [dataProject, setDataProject] = useState<DataType>({projectType: 'commercial', isPromoted: 0});

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [files, setFiles] = useState<UploadFile<any>[]>([]);

  const onRenderFooterContent = React.useCallback(
    () => (
      <Stack horizontal tokens={{ childrenGap: 5 }}>
        {!submitting && (
          <>
            <PrimaryButton text='Save' onClick={onSubmit} />
            <DefaultButton text='Cancel' onClick={() => props.onCLose()} />
          </>
        )}
        {submitting && <Spinner label='saving...' ariaLive='assertive' labelPosition='right' />}
      </Stack>
    ),
    [submitting, dataProject]
  );

  const onUpdateFiles = useCallback((fs: UploadFile<any>[]) => {
    setFiles(fs);
  }, []);

  const getYears = ():IDropdownOption[] => {
    let years = [];
    for (var x=2000; x < 2040; x++) {
      years.push({key: x + '', text: x + ''});
    }
    return years;
  }

  return (
    <Panel
      isOpen={true}
      onDismiss={() => props.onCLose()}
      type={PanelType.medium}
      closeButtonAriaLabel='Close'
      headerText='Tambah Proyek'
      onRenderFooterContent={onRenderFooterContent}
    >
      <Stack>
        <Stack>
          <Label>Gambar Produk</Label>
          <PicturesWall onUpdate={onUpdateFiles} />
        </Stack>
        <TextField
          required
          label='Nama Proyek'
          value={dataProject.name}
          errorMessage={dataProject.errorName}
          onChange={(e, v) => {
            setDataProject({
              ...dataProject,
              name: v,
              errorName: validationService.combination(v, ['required', 'limit'], { maxChars: 255 }).message,
            });
          }}
        />
        <Stack.Item>
          <Dropdown
            required
            label='Tipe Proyek'
            selectedKey={dataProject.projectType}
            options={[{key: 'commercial', text: 'Bangunan Komersil'}, {key: 'residential', text: 'Bangunan Residensial'}]}
            onChange={(e, op) => op && setDataProject({ ...dataProject, projectType: op.key as string })}
          />
        </Stack.Item>
        <TextField
          label='Detail Proyek'
          value={dataProject.description}
          errorMessage={dataProject.errorDescription}
          multiline
          resizable={false}
          rows={3}
          onChange={(e, v) => {
            setDataProject({
              ...dataProject,
              description: v,
              errorDescription: validationService.combination(v, ['limit'], { maxChars: 2000 }).message,
            });
          }}
        />
        <TextField
          label='Lokasi/Alamat Proyek'
          multiline
          resizable={false}
          rows={3}
          value={dataProject.address}
          errorMessage={dataProject.errorAddress}
          onChange={(e, v) => {
            setDataProject({
              ...dataProject,
              address: v,
              errorAddress: validationService.combination(v, ['limit'], { maxChars: 255 })
                .message,
            });
          }}
        />
        {/*<Stack.Item>
          <Dropdown
            required
            label='Country'
            placeholder='Select an Country'
            selectedKey={dataProject.country}
            options={countries.map((c) => ({ key: c.name, text: c.name }))}
            onChange={(e, op) => op && setDataProject({ ...dataProject, country: op.text, city: undefined })}
          />
        </Stack.Item>
        <Stack.Item>
          <Dropdown
            required
            label='City'
            placeholder='Select an City'
            selectedKey={dataProject.city}
            options={
              countries
                .find((f) => f.name === dataProject.country)
                ?.cities.map((c) => ({ key: c, text: c })) || []
            }
            disabled={countries.find((f) => f.name === dataProject.country) === undefined}
            onChange={(e, op) => op && setDataProject({ ...dataProject, city: op.text })}
          />
          </Stack.Item>*/}
        <Stack.Item>
          <Dropdown
            label='Tahun Pegerjaan'
            placeholder='Pilih tahun pengerjaan ...'
            selectedKey={dataProject.country}
            options={getYears()}
            onChange={(e, op) => op && setDataProject({ ...dataProject, year: op.key as string })}
          />
        </Stack.Item>
        <Stack.Item styles={{root: {marginTop: '10px !important'}}}>
          <Checkbox label='Tampilkan di halaman depan' checked={dataProject.isPromoted === 1} onChange={(ev, checked) => setDataProject({...dataProject, isPromoted: checked ? 1 : 0})} />
        </Stack.Item>
      </Stack>
    </Panel>
  );

  async function onSubmit() {
    const fd = new FormData();
    Object.entries(dataProject).map((d) => {
      fd.append(d[0], d[1] + '');
    });
    files.map((f) => f.originFileObj && fd.append('files[]', f.originFileObj));
    setSubmitting(true);
    try {
      await ProjectServices.create(fd);
      props.onCLose(true);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  }
};

export default AddProjectPanel;
