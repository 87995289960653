import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import Cookies from 'js-cookie';

// import services
import UserServices from '../../services/user/auth';

// import styles
import styles from './styles.module.scss';

// import subpages
import Dashboard from './supPages/dashboard';

// import utils
import pages from '../../utils/pages.json';

// import context
import { useGlobalState } from '../../context/global';

// import components
import HeaderComponent from '../../components/header';
import { REFRESH } from '../../props/keys';

// import subpages
import Projects from './supPages/projects';
import User from './supPages/user';

type HomePageProps = {};

const HomePage: React.FC<HomePageProps> = (props) => {
  const navigate = useNavigate();
  const { dispatch } = useGlobalState();

  useEffect(() => {
    getCurrentUser();
    return () => {};
  }, []);

  return (
    <Stack className={styles.container}>
      <HeaderComponent />

      <Routes>
        <Route path={pages.home.index} element={<Dashboard />} />
        <Route path={pages.home.projects.index} element={<Projects />} />
        <Route path={pages.home.user.index} element={<User />} />
      </Routes>
    </Stack>
  );

  async function getCurrentUser() {
    try {
      const user = await UserServices.currentUser();
      dispatch({ type: 'USER', payload: user });
    } catch (error) {
      const err = error as { status: number; message: string };
      if (err.status !== 401) {
        console.error(err.message);
      }
      if (err.status === 401) {
        const refreshToken = Cookies.get(REFRESH) || undefined;
        if (!refreshToken) {
          navigate(pages.auth.index);
        }
      }
    }
  }
};

export default HomePage;
