import { IProjectResorceShort } from '../../props/projects';
import Request from '../request';

// import interfaces

const ProjectServices = {
  retrieve: async (): Promise<IProjectResorceShort[]> => {
    try {
      const res = await Request.get('/api/projects');
      return res;
    } catch (error) {
      throw error;
    }
  },
  create: async (body: FormData): Promise<IProjectResorceShort> => {
    try {
      const res = await Request.post('/api/projects', body, 'multipart/form-data');
      return res;
    } catch (error) {
      throw error;
    }
  },
  update: async (id: string, body: FormData): Promise<IProjectResorceShort> => {
    try {
      const res = await Request.post(`/api/projects/${id}?_method=PUT`, body, 'multipart/form-data');
      return res;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id: string): Promise<IProjectResorceShort> => {
    try {
      const res = await Request.delete(`/api/projects/${id}`);
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default ProjectServices;
