import React, { useEffect, useMemo, useState } from 'react';

import {
  ActionButton,
  CommandBarButton,
  Label,
  PrimaryButton,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';

// import styles
import styles from './styles.module.scss';

// import services
import ProjectServices from '../../../../services/projects';

// import interfaces
import { IProjectResorceShort } from '../../../../props/projects';
import AddProjectPanel from './surfaces/addProjectPanel';
import UpdateProjectPanel from './surfaces/updateProjectPanel';
import GeneralConfig from '../../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faImage, faTrashAlt } from '@fortawesome/pro-light-svg-icons';

type ProjectsProps = {};

const Projects: React.FC<ProjectsProps> = (props) => {
  const [projects, setProjects] = useState<IProjectResorceShort[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [openPanelCrateProject, setOpenPanelCrateProject] = useState<boolean>(false);
  const [updateProjectPanel, setUpdateProjectPanel] = useState<JSX.Element | undefined>();

  useEffect(() => {
    getProjects();
    return () => {
      setProjects([]);
    };
  }, []);

  const columns = useMemo(
    () => [
      {
        key: 'number',
        name: 'No.',
        minWidth: 10,
        maxWidth: 50,
        onRender: (item: IProjectResorceShort, ix: any) => (
          <Stack horizontal styles={{ root: { width: '100%' } }} verticalAlign='center'>
            <Text>{(ix || 0) + 1}</Text>
          </Stack>
        ),
      },
      {
        key: 'image',
        name: 'Image',
        maxWidth: 300,
        minWidth: 300,
        isMultiline: true,
        onRender: (item: IProjectResorceShort, ix: any) => (
          <Stack
            horizontal
            styles={{ root: { width: '100%' } }}
            verticalAlign='center'
            tokens={{ childrenGap: 10 }}
            wrap
          >
            {item.files.map((i) => (
              <img
                key={i.id}
                src={GeneralConfig.assetsHostname + '/' + i.url}
                style={{ height: 60, width: 60 }}
              />
            ))}
          </Stack>
        ),
      },
      {
        key: 'name',
        name: 'Name',
        minWidth: 200,
        onRender: (item: IProjectResorceShort, ix: any) => (
          <Stack
            horizontal
            styles={{ root: { width: '100%' } }}
            verticalAlign='center'
            tokens={{ childrenGap: 10 }}
          >
            <Text>{item.name}</Text>
            <CommandBarButton
              menuProps={{
                items: [
                  {
                    key: 'update',
                    text: 'Update',
                    iconProps: { iconName: 'Pen' },
                    onClick: () => {
                      setUpdateProjectPanel(
                        <UpdateProjectPanel
                          onCLose={(r) => {
                            setUpdateProjectPanel(undefined);
                            if (r) {
                              getProjects();
                            }
                          }}
                          project={item}
                        />
                      );
                    },
                    disabled: false,
                  },
                  {
                    key: 'delete',
                    text: 'Delete',
                    iconProps: { iconName: 'Trash' },
                    onClick: () => deleteProject(item),
                    disabled: false,
                  },
                ],
              }}
            />
          </Stack>
        ),
      },
    ],
    []
  );

  const renderProjectCard = (project: IProjectResorceShort) => {
    const location = [];
    if (project.address) {location.push(project.address);}
    return <Stack className={styles.projectCard} verticalAlign={'space-between'}>
      <Stack>
        <Stack className={styles.image}>
          {project.files && project.files[0] ? <img src={GeneralConfig.assetsHostname + '/' + project.files[0].url} /> : null}
          {!project.files || !project.files[0] ? <Stack className={styles.noImage}><FontAwesomeIcon icon={faImage} fontSize={38} /></Stack> : null}
        </Stack>
        <Stack className={styles.content} tokens={{childrenGap: 10}}>
          <Stack tokens={{childrenGap: 0}}>
            <Text as={'h3'} style={{fontWeight: 600, margin: 0}} variant={'mediumPlus'}>{project.name}</Text>
            {project.description ? <Text variant={'small'}>{project.description}</Text> : null}
          </Stack>
          <Stack tokens={{childrenGap: 5}}>
            <Stack>
              <Text style={{fontWeight: 600}} variant={'small'}>Lokasi / Alamat</Text>
              <Text variant={'small'}>{location.length > 0 ? location.join(', ') : '-'}</Text>
            </Stack>
            <Stack>
              <Text style={{fontWeight: 600}} variant={'small'}>Tahun Pengerjaan</Text>
              <Text variant={'small'}>{project.year || '-'}</Text>
            </Stack>
            </Stack>
        </Stack>
      </Stack>
      <Stack className={styles.footer} horizontal verticalAlign={'center'}>
        <ActionButton onClick={() => setUpdateProjectPanel(<UpdateProjectPanel onCLose={(r) => {setUpdateProjectPanel(undefined); if (r) {getProjects();}}} project={project} />)}>
          <Stack styles={{root: {width: '100%'}}} horizontal tokens={{childrenGap: 5}} horizontalAlign={'center'}>
            <FontAwesomeIcon icon={faEdit} style={{marginTop: 2}} />
            <Text variant={'small'}>Edit</Text>
          </Stack>
        </ActionButton>
        <ActionButton onClick={() => deleteProject(project)}>
          <Stack styles={{root: {width: '100%'}}} horizontal tokens={{childrenGap: 5}} horizontalAlign={'center'}>
            <FontAwesomeIcon icon={faTrashAlt} style={{marginTop: 2}} />
            <Text variant={'small'}>Hapus</Text>
          </Stack>
        </ActionButton>
      </Stack>
    </Stack>
  }

  const renderProjects = () => {
    const promotedProjects = projects.filter((p) => p.isPromoted);
    const notPromotedProjects = projects.filter((p) => !p.isPromoted);

    return <Stack tokens={{childrenGap: 40}}>
      <Stack tokens={{childrenGap: 5}}>
        <Text as={'h3'} style={{fontWeight: 600}} variant={'mediumPlus'}>Proyek yang Ditampilkan di Halaman Utama</Text>
        <Stack horizontal wrap tokens={{childrenGap: 20}}>
          {promotedProjects.map((project) => renderProjectCard(project))}
        </Stack>
      </Stack>
      <Stack tokens={{childrenGap: 5}}>
        <Text as={'h3'} style={{fontWeight: 600}} variant={'mediumPlus'}>Proyek Lainnya</Text>
        <Stack horizontal wrap tokens={{childrenGap: 20}}>
          {notPromotedProjects.map((project) => renderProjectCard(project))}
        </Stack>
      </Stack>
    </Stack>;
  }

  return (
    <Stack className={styles.container}>
      {openPanelCrateProject && (
        <AddProjectPanel
          onCLose={(r) => {
            setOpenPanelCrateProject(false);
            r && getProjects();
          }}
        />
      )}
      <Stack horizontal horizontalAlign='space-between'>
        <Text as={'h2'} variant='xxLarge'>Daftar Proyek</Text>
        <PrimaryButton
          text='Tambah Proyek'
          iconProps={{ iconName: 'Add' }}
          onClick={() => setOpenPanelCrateProject(true)}
        />
      </Stack>
      {/*<ShimmeredDetailsList
        selectionMode={SelectionMode.none}
        items={projects}
        enableShimmer={!loaded}
        columns={columns}
        />*/}
      {renderProjects()}
      {updateProjectPanel}
    </Stack>
  );

  async function getProjects() {
    setLoaded(false);
    try {
      const projects = await ProjectServices.retrieve();
      setProjects(projects);
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
    }
  }

  async function deleteProject(item: IProjectResorceShort) {
    try {
      await ProjectServices.delete(item.id);
      getProjects();
    } catch (error) {}
  }
};

export default Projects;
