import { IProjectFilesResourceShort } from '../../props/projects';
import Request from '../request';

// import interfaces

const ProjectFileServices = {
  delete: async (projectId: string, id: string): Promise<IProjectFilesResourceShort> => {
    try {
      const res = await Request.delete(`/api/projects/${projectId}/files/${id}`);
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default ProjectFileServices;
