import React from 'react';

// import antd

// import styles
import styles from './styles.module.scss';
import { Icon, Stack, Text } from '@fluentui/react';
import { useGlobalState } from '../../context/global';

import pages from '../../utils/pages.json';
import { Link, NavLink } from 'react-router-dom';
import Logo from './../../assets/logo.png';

type HeaderComponentProps = {};

const HeaderComponent: React.FC<HeaderComponentProps> = (props) => {
  const { state } = useGlobalState();
  return (
    <Stack
      className={styles.container}
      horizontal
      horizontalAlign='space-between'
      verticalAlign='center'
    >
      <Stack grow={1} horizontal verticalAlign={'center'}>
        <img src={Logo} className={styles.logo} />
        <NavLink to={'/projects'} className={styles.navLink}>Daftar Proyek</NavLink>
      </Stack>
      <Stack>
        <Link to={pages.home.user.index}>
          <Stack
            horizontal
            verticalAlign='center'
            tokens={{ childrenGap: 10 }}
            styles={{ root: { cursor: 'pointer' } }}
          >
            <Icon iconName='CircleUser' styles={{ root: { fontSize: 30, color: '#ffffff' } }} />
            <Text styles={{ root: { color: '#ffffff' } }}>{state.user?.details?.name}</Text>
          </Stack>
        </Link>
      </Stack>
    </Stack>
  );
};

export default HeaderComponent;
