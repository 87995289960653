import React, { createContext, Dispatch, useReducer, useContext, PropsWithChildren, Reducer } from 'react';
import { reducer } from './reducer';
import { TAction, TState } from './types';

const initialState: TState = {
  isSignedIn: false,
  darkMode: false,
};

type TContext = {
  state: typeof initialState;
  dispatch: Dispatch<TAction>;
};

const context = createContext<TContext>({
  state: initialState,
  dispatch: () => {},
});

function GlobalState(props: PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer<Reducer<TState, TAction>>(reducer, initialState);
  const { Provider } = context;
  return <Provider value={{ state, dispatch }} {...props} />;
}

function useGlobalState() {
  const contextValue = useContext(context);
  if (contextValue === undefined) throw new Error(`No provider for AppContext given`);
  return contextValue;
}

export { GlobalState, useGlobalState };
