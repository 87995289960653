import React, { useState } from 'react';
import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, Stack, TextField } from '@fluentui/react';
import { validationService } from '../../../../../services/validation';
import UserServices from '../../../../../services/user/auth';

// import services

// import config

// import utils

// import components

// import props

type UpdatePasswordProps = {
  onCLose(): void;
};

const UpdatePassword: React.FC<UpdatePasswordProps> = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const [dataPassword, setDataPassword] = useState<{
    oldPassword?: string;
    newPassword?: string;
    errorOldPassword?: string;
    errorNewPassword?: string;
  }>({});

  const onRenderFooterContent = React.useCallback(
    () => (
      <Stack horizontal tokens={{ childrenGap: 5 }}>
        {!submitting && (
          <>
            <PrimaryButton text='Save' onClick={onSubmit} />
            <DefaultButton text='Cancel' onClick={props.onCLose} />
          </>
        )}
        {submitting && <Spinner label='saving...' ariaLive='assertive' labelPosition='right' />}
      </Stack>
    ),
    [dataPassword]
  );

  return (
    <Panel
      isOpen={true}
      onDismiss={() => props.onCLose()}
      type={PanelType.medium}
      closeButtonAriaLabel='Close'
      headerText='Update Project'
      onRenderFooterContent={onRenderFooterContent}
    >
      <TextField
        label='Old Password'
        value={dataPassword.oldPassword}
        type='password'
        onChange={(e, v) => {
          setDataPassword({
            ...dataPassword,
            oldPassword: v,
            errorOldPassword: validationService.combination(v, ['required', 'limit'], { maxChars: 255 })
              .message,
          });
        }}
      />
      <TextField
        label='New Password'
        value={dataPassword.newPassword}
        type='password'
        onChange={(e, v) => {
          setDataPassword({
            ...dataPassword,
            newPassword: v,
            errorNewPassword: validationService.combination(v, ['required', 'limit'], { maxChars: 255 })
              .message,
          });
        }}
      />
    </Panel>
  );

  async function onSubmit() {
    const fd = new FormData();
    if (dataPassword.oldPassword && dataPassword.newPassword) {
      fd.append('oldPassword', dataPassword.oldPassword);
      fd.append('newPassword', dataPassword.newPassword);
      setSubmitting(true);
      try {
        await UserServices.updatePassword(fd);
        logout();
      } catch (error) {
        setSubmitting(false);
      }
    }
  }

  async function logout() {
    try {
      await UserServices.logout();
    } catch (error) {}
  }
};

export default UpdatePassword;
