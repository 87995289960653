import React, { useState } from 'react';

// import ui
import { PrimaryButton, Stack, Text, TextField } from '@fluentui/react';

// import styles
import styles from './../styles.module.scss';

import { validationService } from '../../../../services/validation';
import UserServices from '../../../../services/user/auth';
import { useLocation, useNavigate } from 'react-router-dom';

// import utils
import pages from '../../../../utils/pages.json';

type LoginPageProps = {};

type DataLoginType = {
  username?: string;
  password?: string;
  errorUsername?: string;
  errorPassword?: string;
};

const LoginPage: React.FC<LoginPageProps> = (props) => {
  const [data, setData] = useState<DataLoginType>({});
  const [submiting, setSubmiting] = useState<boolean>(false);

  const navigate = useNavigate();

  return (
    <Stack className={styles.container} horizontalAlign='center' verticalAlign='center'>
      <Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: '100%', padding: '0 30px' } }}>
        <TextField
          label='Username'
          value={data.username}
          onChange={(e, v) => {
            setData({
              ...data,
              username: v,
              errorUsername: validationService.combination(v, ['required', 'limit'], { maxChars: 255 })
                .message,
            });
          }}
          errorMessage={data.errorUsername}
        />
        <TextField
          label='Password'
          type='password'
          value={data.password}
          onChange={(e, v) => {
            setData({
              ...data,
              password: v,
              errorPassword: validationService.combination(v, ['required'], { maxChars: 255 }).message,
            });
          }}
          errorMessage={data.errorPassword}
        />
        <PrimaryButton text='Login' onClick={onSubmit} disabled={submiting} />
      </Stack>
    </Stack>
  );

  async function onSubmit() {
    if (data.username && data.password) {
      setSubmiting(true);
      try {
        await UserServices.login(data.username, data.password);
        setSubmiting(false);
        navigate(pages.home.index);
      } catch (error) {
        setSubmiting(false);
      }
    }
  }
};

export default LoginPage;
