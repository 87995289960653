import React, { useState } from 'react';
import { ActionButton, Icon, Stack, Text } from '@fluentui/react';

// import styles
import styles from './styles.module.scss';
import UpdatePassword from './surfaces/panelUpdatePassword';

type UserProps = {};

const User: React.FC<UserProps> = (props) => {
  const [updatePassword, setUpdatePassword] = useState<JSX.Element | undefined>(undefined);
  return (
    <Stack className={styles.container}>
      <Stack horizontalAlign={'center'} className={styles.passwordComponent}>
        <Stack tokens={{ childrenGap: 20 }} className={styles.content}>
          <Stack horizontalAlign={'center'} tokens={{ childrenGap: 10 }}>
            <Text as={'h3'} variant={'xLargePlus'}>
              Password
            </Text>
            <Icon iconName={'Permissions'} className={styles.headerIcon} />
          </Stack>
          <Stack horizontalAlign={'baseline'} tokens={{ childrenGap: 10 }}>
            <Text style={{ textAlign: 'center' }}>
              Update your password to make it stronger, or change it someone accidentally knows it.
            </Text>
          </Stack>
        </Stack>
        <Stack className={styles.footer}>
          <ActionButton
            onClick={() => {
              setUpdatePassword(<UpdatePassword onCLose={() => setUpdatePassword(undefined)} />);
            }}
            className={styles.footerButton}
            iconProps={{ iconName: 'Pen' }}
            text={'Change password'}
          />
        </Stack>
      </Stack>
      {updatePassword}
    </Stack>
  );
};

export default User;
